<template>
  <div>
    customer ข้างในมี
    <br><br>
    หมายเลข<br>รหัสลูกค้า<br>	วันที่สร้าง<br>	ชื่อ - สกุล<br>	อีเมล์<br>	เบอร์โทรศัพท์<br>	สิทธิ์การเข้าใช้งาน<br>
    กลุ่มผู้ใช้งาน<br>	จัดการ
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>
